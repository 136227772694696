























import Base from '../../application/models/Base'
import { Component } from 'vue-property-decorator'

@Component({})
export default class Settings extends Base {
  constructor () {
    super('settings')
  }

  get settings () {
    return {
      obj: this.$store.state.appSettings,
      keys: Object.keys(this.$store.state.appSettings)
    }
  }
}
